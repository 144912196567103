// -----------------------------------------------------------------------------
// When having several themes, this file contains everything related to the
// default one.
// -----------------------------------------------------------------------------
h1 {
  font-family: 'Roboto Condensed', sans-serif;
  margin-bottom: 0.2em;
}
#page-event {
  .event-foto {
    margin: 10px 0;
    img {
      width: 150px;
    }
  }
  .event-link {
    margin: 20px 0;
  }
}
