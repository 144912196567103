/**
 * Basic typography style for copy text
 */
body {
  color: $text-color;
  font: normal 120% / 1.4 $text-font-stack;
  background-color: #ffffff;
}

h3 {
  margin: 0;
}

p {
  margin-block-start: .3em;
  margin-block-end: .3em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}