// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.page-home {
  .card {
    width: 350px;
    margin: 20px;
  }
  .push-state {
    text-transform: uppercase;
  }
}