.card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  
  .card__image {
    display: block;
    width: 100%;
  }
  .card__container {
    width: 100%;
    background-color: #fff;

    .card__title {
      text-transform: uppercase;
      border-left: 2px solid #000;
      padding-left: 5px;
    }

    .card__content {
      padding: 10px 15px;
      font-size: medium;
    }
  }

  .card__tag {
    text-transform: uppercase;
    font-weight: 600;
    font-size: smaller;
    margin-bottom: 5px;

    &:before {
      content: "\25CF";
      font-size: 50px;
      line-height: 1px;
      vertical-align: text-top;
    }

    &--viola {
      color: rgb(203, 0, 253);
    }
  }

  &--full {
    .card__container {
      @include text-over-img;

      .card__title {
        border: none !important;
        padding-left: 0 !important;
      }
    }
  }

  &--img-big {
    .card__image {
      border-radius: 20px;
    }
    .card__container {
      text-align: center;
      background-color: transparent;

      .card__title {
        border: none !important;
        padding-left: 0 !important;
      }
    }
  }
}